





































































































































































































































































































































































































































































































































































































import {
    Component,
	Watch
} from 'vue-property-decorator';
import accountModule from '@/store/modules/accountModule';
import assetsModule from '@/store/modules/assetsModule';
import scheduleModule from '@/store/modules/scheduleModule';
import { mixins } from 'vue-class-component';
import Multiselect from 'vue-multiselect';
import { getNameByEmail } from '@/utils/users';
import { v4 as uuidv4 } from 'uuid';
import {
	getComponent,
    addNumberPadding,
    sleep,
    getElementPosition,
    getElementCenterPosition,
	getConfigEnv,
} from '@/utils/helpers'
import {
    SCHEDULE_CHART_DAY_WIDTH,
    SCHEDULE_ROW_HEIGHT,
    SCHEDULE_PIXELS_TO_CURRENT_DAY,
	SCHEDULE_ROW_STACKED_HEIGHT,
	SCHEDULE_START_DATE_OFFSET_HOURS,
} from '@/lib/constants'

import workspaceModule from '@/store/modules/workspaceModule';
import workflowModule from '@/store/modules/workflowModule';

@Component({
    components: {
        AppLayout: () => getComponent('common/AppLayout'),
        RigSchedule: () => getComponent('schedule/RigSchedule'),
		ComponentDetails: () => getComponent('schedule/ComponentDetails'),
		AddJob: () => getComponent('schedule/AddJob'),
		AddField: () => getComponent('schedule/AddField'),
		AddShift: () => getComponent('schedule/AddShift'),
		EditShift: () => getComponent('schedule/EditShift'),
		AddShiftTemplate: () => getComponent('schedule/AddShiftTemplate'),
		NewShiftWrapper: () => getComponent('schedule/NewShiftWrapper'),
		EditScheduleGroup: () => getComponent('schedule/EditScheduleGroup'),
		EditShiftTemplate: () => getComponent('schedule/EditShiftTemplate'),
		Dropdown: () => getComponent('common/Dropdown'),
		Multiselect,
    },
})

export default class PersonnelSchedulerGantt extends mixins() {

	reloadGanttChartKey = 0
	reloadGanttChartComponentsKey = 0
	totalAssetsDictionaryKey = 0

	chartStartDate = 0

	dropdownTypeShowing = ''
	dropdownLocation: any[] = []
	assetFilterOptions: any[] = [{text: "Test", value: "Test"}]
	assetFiltersSelected: any[] = []
	assetFiltersConfirmed: any[] = []

	userFilterOptions: any[] = [{text: "Test", value: "Test"}]
	userFiltersSelected: any[] = []
	userFiltersConfirmed: any[] = []


	isDeletingTemplate = false

	isHoveringOverTemplateID = ''

	isDeletingTemplateByID = ''

  	shiftTemplates: any[] = [];
	showSaveSuccessfulNotification = false
	showSavingDetailsBannerID = false
	savingDetailsBannerIDText = ''

	showChangeButtons = false
	
	// Loading views
    dataLoading = false
	loadingGanttChart = false
	dataLoadingDeleteRow = false
	savingChartComponentChange = false
	isUpdatingScheduleView = false

	// Gantt chart data
    viewTotalDays = 17
	dates: any[] = []
    currentDate = ''
    rows: any[] = []
	confirmDialogShowing: any = null;
	confirmDialogTitle = ''
	confirmDialogMessage = ''
	popupBackground = false
	addNewJobPopupInnerShowing = false
	addShiftTemplatePopupShowing = false

	addIconPosX = -100
	addIconPosY = -100
	cursorPosComponent = null

	chartStartDatetime
	chartEndDateTime
	isResizingElement = false
	didDragElement = false

	existingTableID = ''
	addNewWorkspacePopupAddField = false

	selectionElement;
	selectionElementStartPoint;
	isSelectingChartArea = false
	totalSelectedComponents: any = {}

	areasExpanded = []
	routesExpanded = []
	padsExpanded = []

	totalAssets: any = []

	assetSpeedDictVals: any = {}


	didHoverOverTitleCellID = null
	didSelectTitleCellID = null
	showAddShiftPopupVal = false

	isLoadingRightArrow = false
	isLoadingLeftArrow = false

	minChartStartDate;
	maxChartEndDate;

	persistentIDs = {}

	initialTotalAssetsDictionary:any = null
	totalAssetsDictionary:any = null
	reverseAssetsDictionary:any = {}

	get filteredAssets() {
		if (this.totalAssetsDictionary == null) {
			return []
		}
		var return_results: any[] = []
		for (const [area_key, area_value] of Object.entries(this.totalAssetsDictionary.areas)) {
			return_results.push({
				level: "area",
				asset_name: area_key,
				// @ts-ignore
				sub_rows: area_value.sub_rows
			})
			// @ts-ignore
			if (!area_value.show_children) {
				continue
			}
			// @ts-ignore
			for (const [route_key, route_value] of Object.entries(area_value.routes)) {
				return_results.push({
					level: "route",
					area: area_key,
					asset_name: route_key,
					// @ts-ignore
					sub_rows: route_value.sub_rows
				})
				// @ts-ignore
				if (!route_value.show_children) {
					continue
				}

				// @ts-ignore
				for (const [pad_key, pad_value] of Object.entries(route_value.pads)) {
					return_results.push({
						level: "pad",
						area: area_key,
						route: route_key,
						asset_name: pad_key,
						// @ts-ignore
						sub_rows: pad_value.sub_rows
					})
					// @ts-ignore
					if (!pad_value.show_children) {
						continue
					}
					// @ts-ignore
					for (const [well_key, well_value] of Object.entries(pad_value.wells)) {
						return_results.push({
							level: "well",
							area: area_key,
							route: route_key,
							pad: pad_key,
							asset_name: well_key,
							// @ts-ignore
							sub_rows: well_value.sub_rows
						})

					}


				}



			}


		}

		return return_results


	}



    async created() {
		await this.setUserOptions()
		await workflowModule.getNestedOperatorAssets()
		window.addEventListener('keydown', 
                         (e) => this.deleteAllSelectedComponents(e));
		window.addEventListener('mouseup', 
                         () => this.didEndSelectionWindow(null));

		this.loadingGanttChart = true
		this.getShiftTemplates()
		
		scheduleModule.getColumnMappingData({})
		scheduleModule.resetScheduledRowComponents()
		scheduleModule.setActiveComponent(null)
		scheduleModule.resetPersonnelScheduleAssets()
		this.initializePage()
		this.originComponentList = []

		this.userFiltersConfirmed = []
		
		// 
		
		if (localStorage.getItem("personnelScheduleUserFilter") != null) {
			this.userFiltersConfirmed = JSON.parse(localStorage.getItem("personnelScheduleUserFilter")!)
			this.userFiltersSelected = JSON.parse(JSON.stringify(this.userFiltersConfirmed))	
		}
		
		if (localStorage.getItem("personnelScheduleAssetFilter") != null) {
			this.assetFiltersConfirmed = JSON.parse(localStorage.getItem("personnelScheduleAssetFilter")!)
			this.assetFiltersSelected = JSON.parse(JSON.stringify(this.assetFiltersConfirmed))	
		}
    }


	async initializePage() {
		var start_datetime = new Date()
		start_datetime.setHours(0,0,0,0);
		this.chartStartDatetime = start_datetime

		scheduleModule.resetScheduledRowComponents()
		this.existingTableID = this.$route.params.table_id
		workspaceModule.setActiveTable({id: this.$route.params.table_id || ''});
        await assetsModule.getEnabledWells();

		var start_datetime = new Date()
		var start_datetime_temp = new Date()
		var minChartStartDate = new Date(start_datetime.setDate(start_datetime.getDate() - 14))
		var maxChartEndDate = new Date(start_datetime_temp.setDate(start_datetime_temp.getDate() + 90))


		const startDateString = `${minChartStartDate.getUTCFullYear()}-${addNumberPadding(minChartStartDate.getUTCMonth() + 1, 2, '0')}-${addNumberPadding(minChartStartDate.getUTCDate(), 2, '0')}T${addNumberPadding(minChartStartDate.getUTCHours(), 2, '0')}:${addNumberPadding(minChartStartDate.getUTCMinutes(), 2, '0')}:${addNumberPadding(minChartStartDate.getUTCSeconds(), 2, '0')}`;
		const endDateString = `${maxChartEndDate.getUTCFullYear()}-${addNumberPadding(maxChartEndDate.getUTCMonth() + 1, 2, '0')}-${addNumberPadding(maxChartEndDate.getUTCDate(), 2, '0')}T${addNumberPadding(maxChartEndDate.getUTCHours(), 2, '0')}:${addNumberPadding(maxChartEndDate.getUTCMinutes(), 2, '0')}:${addNumberPadding(maxChartEndDate.getUTCSeconds(), 2, '0')}`;
		
		await scheduleModule.getPersonnelScheduledShiftsGantt({ schedule_id: scheduleModule.activePersonnelSchedule.ID, start_date: startDateString, end_date: endDateString });

        this.reloadPage()
		await this.setUserOptions()
	}




	async reloadPage() {
		this.loadingGanttChart = true
		this.dates = []

		scheduleModule.resetScheduledRowComponents()

		await this.addDataToGanttChart()
		this.loadingGanttChart = false

	}



	async addDataToGanttChart() {
		var start_datetime = new Date(this.chartStartDatetimeWithOffset().getTime())
		var start_datetime_temp = new Date(this.chartStartDatetimeWithOffset().getTime())
		var end_datetime = new Date(start_datetime_temp.setHours(start_datetime_temp.getHours() + (this.viewTotalDays * 24)))
		end_datetime.setHours(0,0,0,0);
		this.chartEndDateTime = new Date(end_datetime.getTime())

		const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
		"Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
		];
		if (monthNames[this.chartStartDatetimeWithOffset().getMonth()] != monthNames[end_datetime.getMonth()]) {
			this.currentDate = monthNames[this.chartStartDatetimeWithOffset().getMonth()] + " — " + monthNames[end_datetime.getMonth()] + " " + this.chartStartDatetimeWithOffset().getFullYear() // addNumberPadding((new Date().getMonth() + 1), 2, '0') + "/" + addNumberPadding((new Date().getDate()), 2, '0') + "/" + new Date().getFullYear()
		
		} else {
			this.currentDate = monthNames[this.chartStartDatetimeWithOffset().getMonth()] + " " + this.chartStartDatetimeWithOffset().getFullYear() // addNumberPadding((new Date().getMonth() + 1), 2, '0') + "/" + addNumberPadding((new Date().getDate()), 2, '0') + "/" + new Date().getFullYear()
		}

		const startDateString = `${start_datetime.getUTCFullYear()}-${addNumberPadding(start_datetime.getUTCMonth() + 1, 2, '0')}-${addNumberPadding(start_datetime.getUTCDate(), 2, '0')}T${addNumberPadding(start_datetime.getUTCHours(), 2, '0')}:${addNumberPadding(start_datetime.getUTCMinutes(), 2, '0')}:${addNumberPadding(start_datetime.getUTCSeconds(), 2, '0')}`;
		const endDateString = `${end_datetime.getUTCFullYear()}-${addNumberPadding(end_datetime.getUTCMonth() + 1, 2, '0')}-${addNumberPadding(end_datetime.getUTCDate(), 2, '0')}T${addNumberPadding(end_datetime.getUTCHours(), 2, '0')}:${addNumberPadding(end_datetime.getUTCMinutes(), 2, '0')}:${addNumberPadding(end_datetime.getUTCSeconds(), 2, '0')}`;

		// @ts-ignore
		await scheduleModule.getPersonnelScheduledShiftsGanttSubSelection({ schedule_id: scheduleModule.activePersonnelSchedule.ID, start_date: startDateString, end_date: endDateString });
		this.dates = []
		scheduleModule.resetFilterScheduledRowComponents()
		
		// Show chart 30 days
		for (var days = 0; days < this.viewTotalDays; days++) {
			var day_datetime = new Date(start_datetime)
			day_datetime = new Date(day_datetime.setDate(start_datetime.getDate() + days))
			var hours_array: Date[] = []
			var day_object = {
				"day_id": day_datetime.toString(),
				"day_short": day_datetime.getDate(),
				"day": day_datetime,
				"hours": hours_array
			}
			for (var hours = 0; hours < 24; hours++) {
				var hour_datetime = new Date(day_datetime)
				hour_datetime = new Date(hour_datetime.setHours(day_datetime.getHours() + hours))
				day_object.hours.push(hour_datetime)
			}
			this.dates.push(day_object)
		}

		await this.reshuffleFilterScheduleRowComponents()

		var total_results: any[] = []
		for (var x = 0; x < scheduleModule.totalScheduledRowComponents.length; x++) {
			total_results.push({text: scheduleModule.totalScheduledRowComponents[x].title_row.selection_name, value: scheduleModule.totalScheduledRowComponents[x].title_row.selection_name})
		}
		this.totalAssets = total_results

		this.reloadGanttChartKey += 1
		for (var z = 0; z < this.totalAssets.length; z++) {
			this.assetSpeedDictVals[this.totalAssets[z].text] = this.totalAssets[z]
		}

	}



	get filterScheduledRowComponents() {
		var updatedResults:any[] = JSON.parse(JSON.stringify(scheduleModule.totalScheduledRowComponents))
		if (this.assetFiltersConfirmed.length > 0) {
			var localUpdatedResults:any[] = []
			for (var x = 0; x < updatedResults.length; x++) {
				var does_contain_asset = false
				for (var y = 0; y < this.assetFiltersConfirmed.length; y++) {
					if (this.assetFiltersConfirmed[y].value == updatedResults[x].title_row.selection_name) {
						does_contain_asset = true
						break
					}
				}
				if (does_contain_asset) {
					localUpdatedResults.push(updatedResults[x])
				}	
			}
			updatedResults = JSON.parse(JSON.stringify(localUpdatedResults))
		}
		if (this.userFiltersConfirmed.length > 0) {
			var localUpdatedResults:any[] = []
			for (var x = 0; x < updatedResults.length; x++) {
				var does_contain_user = false
				for (var z = 0; z < updatedResults[x].rows.length; z++) {
					for (var a = 0; a < updatedResults[x].rows[z].components.length; a++) {
						for (var y = 0; y < this.userFiltersConfirmed.length; y++) {
							if (this.userFiltersConfirmed[y].value == updatedResults[x].rows[z].components[a].assignee.selection_id) {
								does_contain_user = true
								break
							}
						}
					}
				}

				if (does_contain_user) {
					localUpdatedResults.push(updatedResults[x])
				}	
			}
			updatedResults = JSON.parse(JSON.stringify(localUpdatedResults))
		}

		var localUpdatedResults:any[] = []
		for (var x = 0; x < updatedResults.length; x++) {
			var route_is_collapsed = false
			var area_is_collapsed = false

			// @ts-ignore
			if (!(this.routesExpanded.indexOf(updatedResults[x].route) > -1)) {
				route_is_collapsed = true
			}
			// @ts-ignore
			if (!(this.areasExpanded.indexOf(updatedResults[x].area) > -1)) {
				area_is_collapsed = true
			}
			// @ts-ignore
			if (updatedResults[x].level == "Area" || (updatedResults[x].level == "Route" && (this.areasExpanded.indexOf(updatedResults[x].parent) > -1)) || (updatedResults[x].level == "Pad" && (this.routesExpanded.indexOf(updatedResults[x].parent) > -1) && !area_is_collapsed) || (updatedResults[x].level == "Well" && (this.padsExpanded.indexOf(updatedResults[x].parent) > -1) && !area_is_collapsed && !route_is_collapsed)) {
				localUpdatedResults.push(updatedResults[x])
			}	
		}
		updatedResults = JSON.parse(JSON.stringify(localUpdatedResults))
	

		if (this.areasExpanded.length == 0 && this.routesExpanded.length == 0 && this.padsExpanded.length == 0) {
			var localUpdatedResults:any[] = []
			for (var x = 0; x < updatedResults.length; x++) {
				// @ts-ignore
				if (updatedResults[x].level == "Area") {
					localUpdatedResults.push(updatedResults[x])
				}	
			}
			updatedResults = JSON.parse(JSON.stringify(localUpdatedResults))
		}

		return updatedResults
	}




	clearRowShiftsClicked(component_name) {
		for (var x = 0; x < scheduleModule.totalComponents.length; x++) {
			// for (var y = 0; y < scheduleModule.totalScheduledRowComponents[x].rows.length; y++) {
				// for (var z = 0; z < scheduleModule.totalScheduledRowComponents[x].rows[y].components.length; z++) {
					if (scheduleModule.totalComponents[x].AssetType == "Area") {
						if (component_name == scheduleModule.totalComponents[x].Asset) {
							scheduleModule.deleteScheduledComponent({component_index: x, hidden: true, changed: true})
						}
					} else if (scheduleModule.totalComponents[x].AssetType == "Route") {
						if (component_name == scheduleModule.totalComponents[x].Asset) {
							scheduleModule.deleteScheduledComponent({component_index: x, hidden: true, changed: true})
						}
					} else if (scheduleModule.totalComponents[x].AssetType == "Pad") {
						if (component_name == scheduleModule.totalComponents[x].Asset) {
							scheduleModule.deleteScheduledComponent({component_index: x, hidden: true, changed: true})
						}
					} else if (scheduleModule.totalComponents[x].AssetType == "Well") {
						if (component_name == scheduleModule.totalComponents[x].Asset) {
							scheduleModule.deleteScheduledComponent({component_index: x, hidden: true, changed: true})
						}
					}
					
				// }
			// }
			
		}
		
		scheduleModule.setActiveComponent(null)
		this.reshuffleFilterScheduleRowComponents()
		this.didHoverOverTitleCellID = null
		this.didSelectTitleCellID = null
		
	}





	async constructAssetsDictionary() {
		this.initialTotalAssetsDictionary = {
			"areas": {}
		}
		
		if (workflowModule.nestedOperatorAssetsOutput == null || Object.keys(workflowModule.nestedOperatorAssetsOutput).length == 0) {
			await workflowModule.getNestedOperatorAssets()
		}

		for (const [area_key, area_value] of Object.entries(workflowModule.nestedOperatorAssetsOutput)) {
				var area_uuid = uuidv4()
				if (this.persistentIDs[area_key] != null) {
					area_uuid = this.persistentIDs[area_key]
				} else {
					this.persistentIDs[area_key] = area_uuid
				}
				
				if (this.initialTotalAssetsDictionary["areas"][area_key] == null) {
					this.initialTotalAssetsDictionary["areas"][area_key] = {
						"routes": {},
						"sub_rows": [{components: []}],
						"show_children": false
					}
				}
				this.reverseAssetsDictionary[area_key] = {
					level: "Area",
				}

			
			// @ts-ignore
			for (const [route_key, route_value] of Object.entries(area_value.Routes)) {
				var route_uuid = uuidv4()
				if (this.persistentIDs[route_key] != null) {
					route_uuid = this.persistentIDs[route_key]
				} else {
					this.persistentIDs[route_key] = route_uuid
				}
				if (this.initialTotalAssetsDictionary["areas"][area_key]["routes"][route_key] == null) {
					this.initialTotalAssetsDictionary["areas"][area_key]["routes"][route_key] = {
						"pads": {},
						"sub_rows": [{components: []}],
						"show_children": false
					}
				}
				this.reverseAssetsDictionary[route_key] = {
					level: "Route",
					area: area_key,
				}


				// @ts-ignore
				for (const [pad_key, pad_value] of Object.entries(route_value.Pads)) {
						var pad_uuid = uuidv4()
						if (this.persistentIDs[pad_key] != null) {
							pad_uuid = this.persistentIDs[pad_key]
						} else {
							this.persistentIDs[pad_key] = pad_uuid
						}
						if (this.initialTotalAssetsDictionary["areas"][area_key]["routes"][route_key]["pads"][pad_key] == null) {
							this.initialTotalAssetsDictionary["areas"][area_key]["routes"][route_key]["pads"][pad_key] = {
								"wells": {},
								"sub_rows": [{components: []}],
								"show_children": false
							}
						}
						this.reverseAssetsDictionary[pad_key] = {
							level: "Pad",
							area: area_key,
							route: route_key,
						}

				
					// @ts-ignore
					for (const [well_key, well_value] of Object.entries(pad_value.Wells)) {
							var well_uuid = uuidv4()
							if (this.persistentIDs[well_key] != null) {
								well_uuid = this.persistentIDs[well_key]
							} else {
								this.persistentIDs[well_key] = well_uuid
							}


						this.initialTotalAssetsDictionary["areas"][area_key]["routes"][route_key]["pads"][pad_key]["wells"][well_key] = {
							"sub_rows": [{components: []}],
						}
						this.reverseAssetsDictionary[well_key] = {
							level: "Well",
							area: area_key,
							route: route_key,
							pad: pad_key
						}
					}
				}
			}
		}
	}


	checkIfcomponentMatchesUserInPreviousSubrow(subrows, shift) {
		for (var x = 0; x < subrows.length; x++) {
			if (subrows[x].components.length == 0) {
				continue
			}
			var dayBefore = new Date(shift.StartTime)
			dayBefore.setDate(dayBefore.getDate() - 1)
			if (subrows[x].components[subrows[x].components.length - 1].Username == shift.Username && this.sameDay(new Date(subrows[x].components[subrows[x].components.length - 1].StartTime), dayBefore)) {

				return x
			}
		}
		return null
	}
	
	async reshuffleFilterScheduleRowComponents() {
		
		if (this.totalAssetsDictionary == null || this.totalAssetsDictionary == undefined) {
			await this.constructAssetsDictionary()
		}
		var oldAssetDict:any = null
		if (this.totalAssetsDictionary != null) {
			var oldAssetDict = JSON.parse(JSON.stringify(this.totalAssetsDictionary))
		}
		
		this.totalAssetsDictionary = JSON.parse(JSON.stringify(this.initialTotalAssetsDictionary))
		if (oldAssetDict != null) {
			for (const [area_key, area_value] of Object.entries(oldAssetDict["areas"])) {
				// @ts-ignore
				if (area_value.show_children) {
					this.totalAssetsDictionary["areas"][area_key].show_children = true
				}

				for (const [route_key, route_value] of Object.entries(oldAssetDict["areas"][area_key]["routes"])) {
					// @ts-ignore
					if (route_value.show_children) {
						this.totalAssetsDictionary["areas"][area_key]["routes"][route_key].show_children = true
					}

					for (const [pad_key, pad_value] of Object.entries(oldAssetDict["areas"][area_key]["routes"][route_key]["pads"])) {
						// @ts-ignore
						if (pad_value.show_children) {
							this.totalAssetsDictionary["areas"][area_key]["routes"][route_key]["pads"][pad_key].show_children = true
						}

						for (const [well_key, well_value] of Object.entries(oldAssetDict["areas"][area_key]["routes"][route_key]["pads"][pad_key]["wells"])) {
							// @ts-ignore
							if (well_value.show_children) {
								this.totalAssetsDictionary["areas"][area_key]["routes"][route_key]["pads"][pad_key]["wells"][well_key].show_children = true
							}
						}


					}


				}

			}
		}

		for (var y = 0; y < this.totalComponents.length; y++) {
			
			var copied_start = new Date(new Date(this.totalComponents[y].StartTime + "Z").getTime());
			
			if (this.chartEndDateTime.getTime() <= copied_start.getTime() || copied_start.getTime() < this.chartStartDatetimeWithOffset().getTime()) {
				continue
			}

			var current_asset:any = null
			if (this.totalComponents[y].AssetType == "Area") {
				current_asset = this.totalAssetsDictionary["areas"][this.totalComponents[y].Asset]
			} else if (this.totalComponents[y].AssetType == "Route") {
				current_asset = this.totalAssetsDictionary["areas"][this.reverseAssetsDictionary[this.totalComponents[y].Asset].area]["routes"][this.totalComponents[y].Asset]
			} else if (this.totalComponents[y].AssetType == "Pad") {
				current_asset = this.totalAssetsDictionary["areas"][this.reverseAssetsDictionary[this.totalComponents[y].Asset].area]["routes"][this.reverseAssetsDictionary[this.totalComponents[y].Asset].route]["pads"][this.totalComponents[y].Asset]
			} else if (this.totalComponents[y].AssetType == "Well") {
				current_asset = this.totalAssetsDictionary["areas"][this.reverseAssetsDictionary[this.totalComponents[y].Asset].area]["routes"][this.reverseAssetsDictionary[this.totalComponents[y].Asset].route]["pads"][this.reverseAssetsDictionary[this.totalComponents[y].Asset].pad]["wells"][this.totalComponents[y].Asset]
			}

			if (current_asset["sub_rows"].length == 0) {
				current_asset["sub_rows"].push(this.totalComponents)
			} else {
				var append_to_subrow_index = this.checkIfcomponentMatchesUserInPreviousSubrow(current_asset["sub_rows"], this.totalComponents[y])
				if (append_to_subrow_index != null) {
					current_asset["sub_rows"][append_to_subrow_index].components.push(this.totalComponents[y])

				} else {
					// # Check if component ovrerlaps
					for (var z = 0; z < current_asset["sub_rows"].length; z++) {
						var row = current_asset["sub_rows"][z]
						var does_component_overlap_in_row = this._does_component_overlap_in_row(row, this.totalComponents[y])
						
						if (does_component_overlap_in_row && z < (current_asset["sub_rows"].length - 1)) {
							continue
						}
						else if (!does_component_overlap_in_row) {
							
							current_asset["sub_rows"][z].components.push(this.totalComponents[y])
							break
						}
						else if (does_component_overlap_in_row && z == (current_asset["sub_rows"].length - 1)) {

							current_asset["sub_rows"].push({
								...this.totalComponents[y],
								components: [this.totalComponents[y]]
							})

							break
						}
					}
				}

			}

			if (this.totalComponents[y].AssetType == "Area") {
				this.totalAssetsDictionary["areas"][this.totalComponents[y].Asset] = current_asset
			} else if (this.totalComponents[y].AssetType == "Route") {
				this.totalAssetsDictionary["areas"][this.reverseAssetsDictionary[this.totalComponents[y].Asset].area]["routes"][this.totalComponents[y].Asset] = current_asset
			} else if (this.totalComponents[y].AssetType == "Pad") {
				this.totalAssetsDictionary["areas"][this.reverseAssetsDictionary[this.totalComponents[y].Asset].area]["routes"][this.reverseAssetsDictionary[this.totalComponents[y].Asset].route]["pads"][this.totalComponents[y].Asset] = current_asset
			} else if (this.totalComponents[y].AssetType == "Well") {
				this.totalAssetsDictionary["areas"][this.reverseAssetsDictionary[this.totalComponents[y].Asset].area]["routes"][this.reverseAssetsDictionary[this.totalComponents[y].Asset].route]["pads"][this.reverseAssetsDictionary[this.totalComponents[y].Asset].pad]["wells"][this.totalComponents[y].Asset] = current_asset
			}
			
		}
		
		
		// Add all components to an array
		// Clear all rows for each filterScheduledRowComponent
		// await scheduleModule.setFilterScheduledRowComponents({data: this.constructAssetsDictionary})
		this.reloadGanttChartKey += 1
		this.totalAssetsDictionaryKey += 1

		this.$emit('did-modify-schedule');
	}
	// Create a dictionary of 


	resetActiveTitleRowOptionsDropdown() {
		this.didSelectTitleCellID = null
	}

	didSelectTitleRowOptionsDropdown(id) {
		this.didSelectTitleCellID = id
	}

	didHoverOverTitleCell(id) {
		this.didHoverOverTitleCellID = id
	}
	didLeaveHoverOverTitleCell(id) {
		this.didHoverOverTitleCellID = null
	}


	didSelectAssetDropdownLabel(asset) {
	
		if (asset.level == "area") {
			return this.totalAssetsDictionary.areas[asset.asset_name].show_children ? "expand_more" : "expand_less"
		} else if (asset.level == "route") {
			return this.totalAssetsDictionary.areas[asset.area].routes[asset.asset_name].show_children ? "expand_more" : "expand_less"
		} else if (asset.level == "route") {
			return this.totalAssetsDictionary.areas[asset.area].routes[asset.route].pads[asset.asset_name].show_children ? "expand_more" : "expand_less"
		} else if (asset.level == "well") {
			return ''
		}

		return "expand_less"
		

	}


	didSelectAssetDropdown(asset) {
		if (asset.level == "area") {
			this.totalAssetsDictionary.areas[asset.asset_name].show_children = !this.totalAssetsDictionary.areas[asset.asset_name].show_children
		} else if (asset.level == "route") {
			this.totalAssetsDictionary.areas[asset.area].routes[asset.asset_name].show_children = !this.totalAssetsDictionary.areas[asset.area].routes[asset.asset_name].show_children
		} else if (asset.level == "pad") {
			this.totalAssetsDictionary.areas[asset.area].routes[asset.route].pads[asset.asset_name].show_children = !this.totalAssetsDictionary.areas[asset.area].routes[asset.route].pads[asset.asset_name].show_children
		} else if (asset.level == "well") {
			this.totalAssetsDictionary.areas[asset.area].routes[asset.route].pads[asset.pad].wells[asset.asset_name].show_children = !this.totalAssetsDictionary.areas[asset.area].routes[asset.route].pads[asset.pad].wells[asset.asset_name].show_children
		}
		// this.reloadGanttChartKey += 1
		// if (asset_type == "area") {
		// 	// @ts-ignore
		// 	if (this.areasExpanded.indexOf(asset) > -1) {
		// 		// @ts-ignore
		// 		this.areasExpanded.splice(this.areasExpanded.indexOf(asset), 1)

		// 	} else {
		// 		// @ts-ignore
		// 		this.areasExpanded.push(asset)
		// 	}
		// } else if (asset_type == "route") {
		// 	// @ts-ignore
		// 	if (this.routesExpanded.indexOf(asset) > -1) {
		// 		// @ts-ignore
		// 		this.routesExpanded.splice(this.routesExpanded.indexOf(asset), 1)
		// 	} else {
		// 		// @ts-ignore
		// 		this.routesExpanded.push(asset)
		// 	}
		// } else if (asset_type == "pad") {
		// 	// @ts-ignore
		// 	if (this.padsExpanded.indexOf(asset) > -1) {
		// 		// @ts-ignore
		// 		this.padsExpanded.splice(this.padsExpanded.indexOf(asset), 1)
		// 	} else {
		// 		// @ts-ignore
		// 		this.padsExpanded.push(asset)
		// 	}
		// }
	}

	async didStartSelectionWindow(e) {
		this.totalSelectedComponents = {}
		if (!this.isDraggingElement) {

			this.isSelectingChartArea = true

			if (document.getElementById("selectionElementID") != null) {
				return
			}
			
			document.getElementById("ganttChartSelectableDivID")!.style.zIndex = "99999999"
			var rect = e.target.getBoundingClientRect();
			var x = e.clientX - rect.left; //x position within the element.
			var y = e.clientY - rect.top; 

			if (document.getElementById("selectionElementID") != null) {
				this.selectionElement = document.getElementById("selectionElementID")!
			} else {
				this.selectionElement = document.createElement("div")
			}
			
			this.selectionElement.style.position = "absolute"
			this.selectionElement.style.height = "0px"
			this.selectionElement.style.width = "0px"
			this.selectionElement.style.background = "rgba(255,255,255,0.15)"
			this.selectionElement.style.border = "solid 1px rgba(255,255,255,0.3)"
			this.selectionElement.style.left = x.toString() + "px"
			this.selectionElement.style.top = y.toString() + "px"
			this.selectionElement.style.zIndex = "9999999"
			this.selectionElement.id = "selectionElementID"
			this.selectionElementStartPoint = {x: x, y: y}

			document.getElementById("ganttChartSelectableDivID")!.appendChild(this.selectionElement)
		}
		
		
	}



	getRowColorLeft(row_type) {
		if (row_type == "area") {
			return "background: rgba(0,0,0,0.0);"
		}
		if (row_type == "route") {
			return "background: rgba(0,0,0,0.1);"
		}
		if (row_type == "pad") {
			return "background: rgba(0,0,0,0.2);"
		}
		if (row_type == "well") {
			return "background: rgba(0,0,0,0.3);"
		}
		return "background: rgba(0,0,0,0.0);"
	}


	getRowTitleWidth(row_type) {
		if (row_type == "area") {
			return "padding-left: 0px; width: 220px; "
		}
		if (row_type == "route") {
			return "padding-left: 12px; width: 220px; "
		}
		if (row_type == "pad") {
			return "padding-left: 24px; width: 220px; "
		}
		if (row_type == "well") {
			return "padding-left: 36px; width: 220px; "
		}
		return "padding-left: 0px; width: 220px; "
	}

	
	shouldHighlightComponent(id) {
		return this.totalSelectedComponents[id] != null
	}


	shouldHighlightComponentByElement(elem) {
		var rect1 = elem.getBoundingClientRect();
		var rect2 = this.selectionElement.getBoundingClientRect();
		var overlap = !(rect1.right < rect2.left || 
                rect1.left > rect2.right || 
                rect1.bottom < rect2.top || 
                rect1.top > rect2.bottom)

		return overlap
	}


	async didEndSelectionWindow(e) {
		this.totalSelectedComponents = []
		if (this.selectionElement != null) {
			var totalComponentsList = document.querySelectorAll('[isDraggableComponent="true"]')
			for (var x = 0; x < totalComponentsList.length; x++) {
				if (this.shouldHighlightComponentByElement(totalComponentsList[x])) {
					this.totalSelectedComponents[totalComponentsList[x].id] = totalComponentsList[x]
				}
			}
		}

		this.isSelectingChartArea = false
		if (this.selectionElement != null) {
			this.reloadGanttChartComponentsKey++
		}

		await sleep(300)
		document.getElementById("selectionElementID")?.remove();
		if (document.getElementById("ganttChartSelectableDivID") != null) {
			document.getElementById("ganttChartSelectableDivID")!.style.zIndex = "99999"
		}
		this.selectionElement = null
	}

	didMoveMouse(e) {

		var rect = e.target.getBoundingClientRect();
		var x = e.clientX - rect.left; //x position within the element.
		var y = e.clientY - rect.top; 
		if (this.isSelectingChartArea && !this.isDraggingElement) {
			// Bottom left
			if (x < this.selectionElementStartPoint.x && y > this.selectionElementStartPoint.y) {
				this.selectionElement.style.top = this.selectionElementStartPoint.y.toString() + "px"
				this.selectionElement.style.width = (this.selectionElementStartPoint.x - x).toString() + "px"
				this.selectionElement.style.height = (y - this.selectionElementStartPoint.y).toString() + "px"
				this.selectionElement.style.left = x.toString() + "px"
			} 
			// Bottom right
			else if (x > this.selectionElementStartPoint.x && y> this.selectionElementStartPoint.y) {
				this.selectionElement.style.top = this.selectionElementStartPoint.y.toString() + "px"
				this.selectionElement.style.width = (x - this.selectionElementStartPoint.x).toString() + "px"
				this.selectionElement.style.height = (y - this.selectionElementStartPoint.y).toString() + "px"
				this.selectionElement.style.left = this.selectionElementStartPoint.x.toString() + "px"
			} 
			// Top left
			else if (x < this.selectionElementStartPoint.x && y < this.selectionElementStartPoint.y) {
				this.selectionElement.style.top = y.toString() + "px"
				this.selectionElement.style.width = (this.selectionElementStartPoint.x - x).toString() + "px"
				this.selectionElement.style.height = (this.selectionElementStartPoint.y - y).toString() + "px"
				this.selectionElement.style.left = x.toString() + "px"
			} 
			// Top right
			else if (x > this.selectionElementStartPoint.x && y < this.selectionElementStartPoint.y) {
				this.selectionElement.style.top = y.toString() + "px"
				this.selectionElement.style.width = (x - this.selectionElementStartPoint.x).toString() + "px"
				this.selectionElement.style.height = (this.selectionElementStartPoint.y - y).toString() + "px"
				this.selectionElement.style.left = this.selectionElementStartPoint.x.toString() + "px"
			}
		}
	}


  getDropdownTimeString(time) {
	function pad(n, width, z) {
		z = z || '0';
		n = n + '';
		return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
	}
	if (time == undefined) {
		return ''
	}
	var hour_string = parseInt(time.split(":")[0])
	var minute_string = parseInt(time.split(":")[1])
	  if (hour_string == 0) {
		  return `12:${pad(minute_string, 2, '0')}am`
	  } else if (hour_string < 12) {
		return `${hour_string}:${pad(minute_string, 2, '0')}am`
	  } else if (hour_string == 12) {
		return `12:${pad(minute_string, 2, '0')}pm`
	  } else if (hour_string <= 23) {
		  return `${hour_string - 12}:${pad(minute_string, 2, '0')}pm`
	  }
	  return ''
  }



	getDayOfWeek(date) {
		const dayOfWeek = new Date(date).getDay();    
		return isNaN(dayOfWeek) ? null : 
		['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][dayOfWeek];
	}

  getDayOfWeekStringFromDate(datetime) {
	return this.getDayOfWeek(datetime.day)?.toUpperCase()
  }



  getTextColorForComponent(templateID, email) {
	if (email) {
		return "white"
	} else {
		return "rgba(255,255,255,0.6)"
	}
  }


  getColorForComponent(templateID, email) {

	  if (templateID == null) {
		  if (email) {
			  return "rgba(155,155,155,1)"
		  } else {
			  return "rgba(155,155,155,0.5)"
		  }
	  } else {
		  for (var x = 0; x < this.allShiftTemplates.length; x++) {
			  if (this.allShiftTemplates[x].ID == templateID) {
				  if (email) {
					  return this.allShiftTemplates[x].Color
				  } else {
					  return this.allShiftTemplates[x].Color
				  }
				  
			  }
		  }
	  }
		if (email) {
			return "rgba(155,155,155,1)"
		} else {
			return "rgba(155,155,155,0.5)"
		}
  }

	

  get assetFilterOptionsSpeedDictVals() {
	  return this.assetSpeedDictVals
  }

  get assetFilterOptionsVals() {
	  return this.totalAssets
  }



  get userFilterOptionsVals() {
	return accountModule.reassignmentList.filter((u) => u && u.email).map((i) => ({
      // @ts-ignore
      text: i.name || 'Test User',
      value: i.email || '',
    })).sort((a, b) => a.text.localeCompare(b.text));

  }


  deleteComponentSelected(component_rename) {
	  for (var x = 0; x < scheduleModule.totalComponents.length; x++) {
		//   for (var y = 0; y < scheduleModule.totalScheduledRowComponents[x].rows.length; y++) {
			//   for (var z = 0; z < scheduleModule.totalScheduledRowComponents[x].rows[y].components.length; z++) {
				  if (component_rename.component_id == scheduleModule.totalComponents[x].ID) {
					  
					  scheduleModule.deleteScheduledComponent({component_index: x, hidden: true, changed: true})
				  }
			//   }
		//   }
	  }

        this.popupBackground = false
		scheduleModule.setActiveComponent(null)
		this.showChangeButtons = true
		this.reshuffleFilterScheduleRowComponents()
	  
  }


  hideDropdown() {
	  this.dropdownLocation = []
	  if (this.dropdownTypeShowing == 'AssetsFilter') {
		  this.assetFiltersSelected = JSON.parse(JSON.stringify(this.assetFiltersConfirmed))
	  }
	  if (this.dropdownTypeShowing == 'UsersFilter') {
		  this.userFiltersSelected = JSON.parse(JSON.stringify(this.userFiltersConfirmed))
	  }  
  }

  confirmDropdown() {
	  if (this.dropdownTypeShowing == 'AssetsFilter') {
		  this.assetFiltersConfirmed = JSON.parse(JSON.stringify(this.assetFiltersSelected))
		  localStorage.setItem('personnelScheduleAssetFilter', JSON.stringify(this.assetFiltersConfirmed));
	  }
	  
	  if (this.dropdownTypeShowing == 'UsersFilter') {
		  this.userFiltersConfirmed = JSON.parse(JSON.stringify(this.userFiltersSelected))
		  localStorage.setItem('personnelScheduleUserFilter', JSON.stringify(this.userFiltersConfirmed));
		  
	  }
  }

	didSelectAssetFilterDropdown(e) {
		const rect = e.srcElement.getBoundingClientRect()
		this.dropdownLocation = [rect.x - 0, rect.y + 38]
		this.dropdownTypeShowing = "AssetsFilter"
	}


	didSelectUsersFilterDropdown(e) {
		const rect = e.srcElement.getBoundingClientRect()
		this.dropdownLocation = [rect.x - 0, rect.y + 38]
		this.dropdownTypeShowing = "UsersFilter"
	}


	async deleteShiftTemplate(templateID) {
		this.isDeletingTemplateByID = templateID
		await scheduleModule.deleteShiftTemplate({id: templateID})
		this.shiftTemplates = await scheduleModule.getShiftTemplates();
		
		this.isDeletingTemplateByID = ''
		this.isDeletingTemplate = false
		this.isHoveringOverTemplateID = ''

	}

	async editShiftTemplate(templateID) {
		this.shiftTemplates = await scheduleModule.getShiftTemplates();
		
		scheduleModule.setActiveShiftTemplateEdit({template: null})
		this.isHoveringOverTemplateID = ''

	}

	get activeShiftTemplateEdit() {
		return scheduleModule.activeShiftTemplateEdit
	}

	didClickToEditTemplate(template) {

		if (scheduleModule.activeShiftTemplateEdit != null && scheduleModule.activeShiftTemplateEdit.ID == template.ID) {
			scheduleModule.setActiveShiftTemplateEdit({template: null})
		} else {
			scheduleModule.setActiveShiftTemplateEdit({template: template})
		} 
	}

	didClickToDeleteTemplate(id) {
		
		if (this.isDeletingTemplate == id) {
			this.isDeletingTemplate = false
		} else {
			this.isDeletingTemplate = id
		} 
	}



	get personnelScheduleShifts() {
		// @ts-ignore
		return scheduleModule.personnelScheduleShifts
	}

	get editGroupDetails() {
		return scheduleModule.editGroupDetails
	}

	get addShiftRowDetails() {
		return scheduleModule.addShiftRowDetails
	}

	get scheduleColumnWidth() {
		return SCHEDULE_CHART_DAY_WIDTH
	}

	getScheduleRowHeight(row_index, total_rows) {
		return row_index == (total_rows - 1) ? SCHEDULE_ROW_HEIGHT : SCHEDULE_ROW_STACKED_HEIGHT
	}

	get activeComponent() {
		return scheduleModule.activeComponent
	}

    get enabledWells() {
        return assetsModule.enabledWells;
    }

    get currentDay(): number {
        return new Date().getDate()
    }

    get scheduledRowComponents(): any[] {
        return scheduleModule.scheduledRowComponents
    }

    get totalComponents(): any[] {
        return scheduleModule.totalComponents
    }

	get allShiftTemplates() {
		return this.shiftTemplates
	}


	copyAllSelectedElements() {
	  for (var x = 0; x < scheduleModule.totalComponents.length; x++) {
		//   for (var y = 0; y < scheduleModule.totalScheduledRowComponents[x].rows.length; y++) {
			//   for (var z = 0; z < scheduleModule.totalScheduledRowComponents[x].rows[y].components.length; z++) {
				for (const [key, value] of Object.entries(this.totalSelectedComponents)) {
					if (scheduleModule.totalComponents[x].ID == key.replace("group_row_component_", "")) {
						var local_component = JSON.parse(JSON.stringify(scheduleModule.totalComponents[x]))
						local_component.ID = uuidv4()
						local_component.is_from_edit = local_component.is_from_edit
						local_component.changed = true
						local_component.type = "new"
						// var data = {
						// 	assignee: {
						// 		selected: true,
						// 		selection_id: local_component.Username,
						// 		selection_name: local_component.Username
						// 	},
						// 	component_id: uuidv4(),
						// 	ID 
						// 	end_date: local_component.EndDate,
						// 	start_date: local_component.StartDate,
						// 	template_id: local_component.TemplateID,
						// 	title_row: {
						// 		selected: true,
						// 		selection_id: local_component.title_row.selection_id,
						// 		selection_name: local_component.title_row.selection_name
						// 	},
						// 	well: local_component.well,
						// 	is_from_edit: local_component.is_from_edit,
						// 	changed: true,
						// 	type: "new"
						// }
						scheduleModule.addFilterScheduledComponent({data: local_component})
					}
				}
	  }
	  this.reshuffleFilterScheduleRowComponents()
	}


	deleteAllSelectedComponents(e) {
			e = e || window.event;
			var key = e.which || e.keyCode; // keyCode detection
			var ctrl = e.ctrlKey ? e.ctrlKey : ((key === 17) ? true : false); // ctrl detection

			var command = e.metaKey ? e.metaKey : ((key === 91) ? true : false); // ctrl detection

			if ( key == 86 && ctrl ) {
				this.copyAllSelectedElements()
			} else if ( key == 67 && ctrl ) {
			} else if ( key == 86 && command ) {
				this.copyAllSelectedElements()
			} else if ( key == 67 && command ) {
			}


			if( key == 8 || key == 46 ) {
				if (Object.keys(this.totalSelectedComponents).length == 0) {
					return
				}
				var components: any[] = []
				for (const [key, value] of Object.entries(this.totalSelectedComponents)) {
					components.push(key.replace("group_row_component_", ""))
				}
				this.deleteComponentsSelected(components)
				this.totalSelectedComponents = {}
			}

	}

  deleteComponentsSelected(component_renames) {

	  for (var x = 0; x < this.totalComponents.length; x++) {
		for (var a = 0; a < component_renames.length; a++) {
			var component_rename = component_renames[a]
			if (component_rename == this.totalComponents[x].ID) {
				scheduleModule.deleteScheduledComponent({component_index: x, hidden: true, changed: true})
			}
		}

	  }
	  this.reshuffleFilterScheduleRowComponents()
  }

	

	async getShiftTemplates(){
		this.shiftTemplates = await scheduleModule.getShiftTemplates();
	}

	async setUserOptions() {
		const items = await accountModule.getReassignmentList({useExisting: true});
		scheduleModule.setAccountDetails(items)
	}

	sleep(ms) {
		return new Promise((resolve) => setTimeout(resolve, ms));
	}

	closeEditGroupDetailsPopup() {
		this.popupBackground = false
		scheduleModule.setEditGroupDetails(null)
	}



	calculateTotalRowHeight(row_count) {
		return ((SCHEDULE_ROW_HEIGHT - SCHEDULE_ROW_STACKED_HEIGHT) * (row_count - 1)) + SCHEDULE_ROW_HEIGHT
	}


	sameDay(d1, d2) {
	return d1.getFullYear() === d2.getFullYear() &&
		d1.getMonth() === d2.getMonth() &&
		d1.getDate() === d2.getDate();
	}

	fetchGetNameByEmail(email) {
		return getNameByEmail(email)
	}


	callGetNameByEmail(previous_component, current_component, email) {
		if (previous_component != null) {
			var start_date = new Date(current_component.StartTime)
			var next_day_start_date = new Date(new Date(current_component.StartTime).getTime())
			var check_start_date = new Date(previous_component.StartTime)
			next_day_start_date.setDate(next_day_start_date.getDate() - 1)
			start_date.setDate(start_date.getDate()-1);
			if ((next_day_start_date.getTime() > this.chartStartDatetimeWithOffset().getTime()) && this.sameDay(start_date, check_start_date) && current_component.Username == previous_component.Username && current_component.TemplateID == previous_component.TemplateID && current_component.TemplateID != null) {
				return ''
			}
		}

		if (email && email.toLowerCase() != "unassigned") {
			var full_name = getNameByEmail(email).split(" ")
			return full_name.length > 1 ? full_name[0][0] + "." + full_name[1][0] + "." : full_name[0]
		}
		return 'Unassigned'
	}


	chartStartDatetimeWithOffset() {
		var modifyDate = new Date(this.chartStartDatetime.getTime())
		return new Date(modifyDate.setHours(modifyDate.getHours() - SCHEDULE_START_DATE_OFFSET_HOURS))
	}


	async weekArrowSelected(direction) {
		
		var tempStartDate = new Date(this.chartStartDatetime.getTime())
		if (direction.toLowerCase() == "left") {
			this.isLoadingLeftArrow = true
			await this.sleep(50)
			var start_datetime = new Date(tempStartDate.setHours(tempStartDate.getHours() - (7 * 24)))
		} else {
			this.isLoadingRightArrow = true
			await this.sleep(50)
			var start_datetime = new Date(tempStartDate.setHours(tempStartDate.getHours() + (7 * 24)))
		}
		
		start_datetime.setHours(0,0,0,0);

		this.chartStartDatetime = start_datetime
		await this.reloadPage()
		this.isLoadingRightArrow = false
		this.isLoadingLeftArrow = false
	}



	didSelectToday() {
		var start_datetime = new Date(new Date().setHours(new Date().getHours()))
		start_datetime.setHours(0,0,0,0);

		this.chartStartDatetime = start_datetime
		this.reloadPage()
	}


	getFullComponentDetailsByID(id) {
		if (scheduleModule.activePersonnelSchedule != null) {
			// @ts-ignore
			for (var x = 0; x < scheduleModule.activePersonnelSchedule.Rows.length; x++) {
				// @ts-ignore
				for (var y = 0; y < scheduleModule.activePersonnelSchedule.Rows[x].Shifts.length; y++) {
					// @ts-ignore
					if (id == scheduleModule.activePersonnelSchedule.Rows[x].Shifts[y].ID) {
						// @ts-ignore
						return scheduleModule.activePersonnelSchedule.Rows[x].Shifts[y]
					}
				}
			}
		}
	}


	showComponentDetails(component) {
		var detailedComponent = this.getFullComponentDetailsByID(component.component_id)
		if (detailedComponent != null) {
			this.popupBackground = true
			scheduleModule.setActiveComponent(detailedComponent)
		}
	}
	

	addNewJobClicked() {
		this.addNewJobPopupInnerShowing = true
		this.popupBackground = true

	}

	hideAddNewJobClicked() {
		this.addNewJobPopupInnerShowing = false
		this.popupBackground = false

	}


	getComponentLeftPosition(start_date) {
		if (start_date.indexOf('undefined') != -1)
			return "0"

		let date:any = new Date(start_date + 'Z')
		// @ts-ignore
		let days_diff = Math.floor((date-this.chartStartDatetimeWithOffset())/(1000*60*60*24))
		return (days_diff * SCHEDULE_CHART_DAY_WIDTH).toString() + "px"
	}

	getComponentWidth(start_date, end_date) {
		if (start_date.indexOf('undefined') != -1 || end_date.indexOf('undefined') != -1)
			return "0"
		let s:any = new Date(start_date+ 'Z')
		let e:any = new Date(end_date+ 'Z')
		let width_days_diff = Math.floor((e-s-1)/(1000*60*60*24))
		s.setDate(s.getDate() + width_days_diff - 1)
		return (SCHEDULE_CHART_DAY_WIDTH).toString() + "px"
	}


	async publishChangesClicked() {
		this.isUpdatingScheduleView = true
		this.setSavingDetailsBannerID("Saving updated schedule (this may take a minute)")
		var promises: any[] = [];
		var publishDeleteCustomShift: any[] = []
		var publishPostCustomShift: any[] = []
		var publishDeleteCustomShift: any[] = []
		var publishUpdateShift: any[] = []
		for (var i = 0; i < scheduleModule.totalComponents.length; i++) {
			if (scheduleModule.totalComponents[i].should_delete && scheduleModule.totalComponents[i].type != "new") {
				// publishDicts.push({ShiftID: scheduleModule.totalComponents[i].ID})
				// Delete an existing shift
				publishDeleteCustomShift.push({ShiftID: scheduleModule.totalComponents[i].ID})
			}
			else if (!scheduleModule.totalComponents[i].should_delete && scheduleModule.totalComponents[i].type == "new") {
				// Create a new shift
				
				if (scheduleModule.totalComponents[i].is_from_edit == null) {

					publishPostCustomShift.push({
						date: scheduleModule.totalComponents[i].StartTime,
						startTime: scheduleModule.totalComponents[i].StartTime,
						endTime: scheduleModule.totalComponents[i].EndTime,
						username: scheduleModule.totalComponents[i].Username,
						assetType: scheduleModule.totalComponents[i].AssetType,
						asset: scheduleModule.totalComponents[i].Asset,
						recurType: '',
						recurStartDate: null,
						recurEndDate: null,
						weekdays: null,
						onDays: null,
						offDays: null,
						workspace_id: scheduleModule.activePersonnelWorkspace.ID,
						schedule_id: scheduleModule.activePersonnelSchedule.ID,
						RowID: scheduleModule.totalComponents[i].Asset,
						shiftTemplateID: scheduleModule.totalComponents[i].TemplateID,
						operator: getConfigEnv('OPERATOR_LOWERCASED'),
					})

					// promises.push(scheduleModule.postCustomShift({
					// 	date: scheduleModule.totalComponents[i].StartTime,
					// 	startTime: scheduleModule.totalComponents[i].StartTime,
					// 	endTime: scheduleModule.totalComponents[i].EndTime,
					// 	username: scheduleModule.totalComponents[i].Username,
					// 	assetType: scheduleModule.totalComponents[i].AssetType,
					// 	asset: scheduleModule.totalComponents[i].Asset,
					// 	recurType: '',
					// 	recurStartDate: null,
					// 	recurEndDate: null,
					// 	weekdays: null,
					// 	onDays: null,
					// 	offDays: null,
					// 	workspace_id: scheduleModule.activePersonnelWorkspace.ID,
					// 	schedule_id: scheduleModule.activePersonnelSchedule.ID,
					// 	RowID: scheduleModule.totalComponents[i].Asset,
					// 	shiftTemplateID: scheduleModule.totalComponents[i].TemplateID,
					// }))

				} else {
					publishPostCustomShift.push({
						date: scheduleModule.totalComponents[i].is_from_edit.date,
						startTime: scheduleModule.totalComponents[i].StartTime,
						endTime: scheduleModule.totalComponents[i].EndTime,
						username: scheduleModule.totalComponents[i].Username,
						assetType: scheduleModule.totalComponents[i].AssetType,
						asset: scheduleModule.totalComponents[i].Asset,
						recurType: null,
						recurStartDate: null,
						recurEndDate: null,
						weekdays: null,
						onDays: null,
						offDays: null,
						workspace_id: scheduleModule.totalComponents[i].is_from_edit.workspace_id,
						schedule_id: scheduleModule.totalComponents[i].is_from_edit.schedule_id,
						RowID: scheduleModule.totalComponents[i].is_from_edit.RowID,
						shiftTemplateID: scheduleModule.totalComponents[i].is_from_edit.shiftTemplateID,
						operator: getConfigEnv('OPERATOR_LOWERCASED')
					})
					// promises.push(scheduleModule.postCustomShift({
					// 	date: scheduleModule.totalComponents[i].is_from_edit.date,
					// 	startTime: scheduleModule.totalComponents[i].StartTime,
					// 	endTime: scheduleModule.totalComponents[i].EndTime,
					// 	username: scheduleModule.totalComponents[i].Username,
					// 	assetType: scheduleModule.totalComponents[i].AssetType,
					// 	asset: scheduleModule.totalComponents[i].Asset,
					// 	recurType: null,
					// 	recurStartDate: null,
					// 	recurEndDate: null,
					// 	weekdays: null,
					// 	onDays: null,
					// 	offDays: null,
					// 	workspace_id: scheduleModule.totalComponents[i].is_from_edit.workspace_id,
					// 	schedule_id: scheduleModule.totalComponents[i].is_from_edit.schedule_id,
					// 	RowID: scheduleModule.totalComponents[i].is_from_edit.RowID,
					// 	shiftTemplateID: scheduleModule.totalComponents[i].is_from_edit.shiftTemplateID,
					// }))
				}
				
			
			}
			else if (scheduleModule.totalComponents[i].changed && scheduleModule.totalComponents[i].type != "new") {
				publishUpdateShift.push({
					username: scheduleModule.totalComponents[i].Username,
					startTime: scheduleModule.totalComponents[i].StartTime,
					endTime: scheduleModule.totalComponents[i].EndTime,
					assetType: scheduleModule.totalComponents[i].AssetType,
					asset: scheduleModule.totalComponents[i].Asset,
					ShiftID: scheduleModule.totalComponents[i].ID,
					TemplateID: scheduleModule.totalComponents[i].TemplateID,
					operator: getConfigEnv('OPERATOR_LOWERCASED')
				})
				// Update an existing shift - Asset, asset type, start date and end date
				// promises.push(scheduleModule.updateShiftScheduleDetails({
				// 	username: scheduleModule.totalComponents[i].Username,
				// 	startTime: scheduleModule.totalComponents[i].StartTime,
				// 	endTime: scheduleModule.totalComponents[i].EndTime,
				// 	assetType: scheduleModule.totalComponents[i].AssetType,
				// 	asset: scheduleModule.totalComponents[i].Asset,
				// 	ShiftID: scheduleModule.totalComponents[i].ID,
				// 	TemplateID: scheduleModule.totalComponents[i].TemplateID,
				// }))
			}
				// }
			// }
		}

		if (publishDeleteCustomShift.length > 0) {
			promises.push(scheduleModule.deleteCustomShiftBatch({
				deleteShifts: publishDeleteCustomShift
			}))
		}

		if (publishUpdateShift.length > 0) {
			promises.push(scheduleModule.putCustomShiftBatch({
				putShifts: publishUpdateShift
			}))
		}

		if (publishPostCustomShift.length > 0) {
			promises.push(scheduleModule.postCustomShiftBatch({
				newShifts: publishPostCustomShift
			}))
		}

		Promise.all(promises).then(() => {
			// this.didUpdatePage()
			this.removeSavingDetailsBannerID(5000, "Your changes have been published")
			this.initializePage()
			this.$emit('did-modify-schedule');
			this.isUpdatingScheduleView = false
			
		}, function (err) {
			// error occurred
		});
		
	}

	async didUpdatePage() {
			
			await this.addDataToGanttAsync()
			this.originComponentList = []
			await this.reloadPage()
			this.savingChartComponentChange = false
			if (this.$route.params.schedule_id != null) {	
				// @ts-ignore
				scheduleModule.setActiveSchedule({
					id: this.$route.params.schedule_id || ''
				});
			}
	}

	closeConfirmDialog() {
		this.confirmDialogShowing = null
	}

	// Pulled in from outside
	getComponentDateRangeFromChartLocation(component) {

		var target_element_pos = getElementPosition(component, "DRAGGED")
		var target_element_min_x = target_element_pos.x
		var target_element_max_x = target_element_pos.x + component.getBoundingClientRect().width

		var new_start_of_day = (Math.floor((target_element_min_x - SCHEDULE_PIXELS_TO_CURRENT_DAY) / SCHEDULE_CHART_DAY_WIDTH) + this.chartStartDatetimeWithOffset().getDate())
		var new_end_of_day = (Math.floor((target_element_max_x - SCHEDULE_PIXELS_TO_CURRENT_DAY) / SCHEDULE_CHART_DAY_WIDTH) + this.chartStartDatetimeWithOffset().getDate())
		var firstDayStart = new Date(this.chartStartDatetimeWithOffset().getFullYear(), this.chartStartDatetimeWithOffset().getMonth(), 1);
		var firstDayEnd = new Date(this.chartStartDatetimeWithOffset().getFullYear(), this.chartStartDatetimeWithOffset().getMonth(), 1);
		var new_start_date = firstDayStart.setDate((firstDayStart.getDate()) + (new_start_of_day + 1));
		var new_end_date = firstDayEnd.setDate((firstDayEnd.getDate()) + (new_end_of_day + 1));

		return [new Date(new_start_date), new Date(new_end_date)]
	}



	getClosestRowIDOnDragEnd(element) {
		var elementCenter = getElementCenterPosition(element, "DRAGGED")
		var ganttChartDateTitleLabeldID = document.getElementById("ganttChartDateTitleLabeldID")
		let maxYDatesLabel = getElementCenterPosition(ganttChartDateTitleLabeldID, "DRAGGED").y + ganttChartDateTitleLabeldID!.getBoundingClientRect().height
		var rowID = null

		if (elementCenter.y <= maxYDatesLabel) {
			// @ts-ignore
			return "0<>" + document.getElementById("ganttChartScrollableContentID")!.childNodes[1].id.replace("group_row_wrapper_","")
		}




							// v-for="(filterScheduledRowComponent, filterScheduleIndex) in filteredAssets" 
							// :key="filterScheduledRowComponent.asset_name" 
							// :id="'group_row_wrapper_' + filterScheduledRowComponent.asset_name"
							// style="position: relative; max-width: 1000px; clear: both; margin-top: 0px; margin-bottom: 0px; padding-top: 0px; padding-bottom: 0px; float: left;"
							// :style="'height: 1px; min-height: ' + calculateTotalRowHeight(filterScheduledRowComponent.sub_rows.length)

		for (var x = 0; x < this.filteredAssets.length; x++) {
			let filterScheduledRowComponent = this.filteredAssets[x]
			for (var y = 0; y < filterScheduledRowComponent.sub_rows.length; y++) {
				// @ts-ignore
				rowID = y.toString() + '<>' + filterScheduledRowComponent.asset_name
				var rowElement = document.getElementById(rowID!) !
				if (rowElement == null) {
					continue
				}
				var rowMinY = getElementCenterPosition(rowElement, "ROW").y - ((rowElement.getBoundingClientRect().height / 2) + 15)
				var rowMaxY = getElementCenterPosition(rowElement, "ROW").y + ((rowElement.getBoundingClientRect().height / 2) + 45)
				if (elementCenter.y > rowMinY && elementCenter.y < rowMaxY) {
					return rowID
				}
			}
		}
		return rowID
	}

	getAllChartComponents(ignoringDraggedComponent: any = null) {
		var totalComponentsList = document.querySelectorAll('[isDraggableComponent="true"]')
		if (ignoringDraggedComponent != null) {
			var otherComponents: any[] = []
			for (var x = 0; x < totalComponentsList.length; x++) {

				if (ignoringDraggedComponent!.getAttribute('componentID') == totalComponentsList[x].getAttribute('componentID')) {
					continue
				}
				otherComponents.push(totalComponentsList[x])
			}
			return otherComponents
		}
		return totalComponentsList
	}



// ***********************************************
// ********** Update component params ************
// ***********************************************

	async updatePersonnelWorkspaceSched() {
		await scheduleModule.updatePersonnelWorkspaceSched()
	}
	async addDataToGanttAsync() {
		await this.addDataToGanttChart()
		scheduleModule.resetPersonnelScheduleShifts()
		this.isUpdatingScheduleView = false
		this.removeSavingDetailsBannerID(5000, "Your changes have been saved")
	}


	setSavingDetailsBannerID(text) {
		this.savingChartComponentChange = true
		this.showSavingDetailsBannerID = true
		this.setSavingDetailsBannerIDText(text)
	}

	setSavingDetailsBannerIDText(text) {
		this.savingDetailsBannerIDText = text
	}

	async removeSavingDetailsBannerID(wait = 5000, text = '') {
		this.savingChartComponentChange = false
		if (text != '') {
			this.setSavingDetailsBannerIDText(text)
		}
		await sleep(wait)
		this.showSavingDetailsBannerID = false
		this.savingDetailsBannerIDText = ''
	}


/* 
   ***********************************************
   ********* Handle dragged components ***********
   ***********************************************
*/

	pos1 = 0
	pos2 = 0
	pos3 = 0
	pos4 = 0
	draggedElement
	originComponentList
	isDraggingElement = false

	addOriginComponent(component) {
		for (var index = 0; index < this.originComponentList.length; index++) {
			if (this.originComponentList[index].id == component.id)
				return
		}
		this.originComponentList.push(component)
	}




	startDrag(e, component) {
		
		this.isDraggingElement = true
		this.isSelectingChartArea = false
		this.dragMouseDown(e, component.component_id)
		component.changed = true
		component.selected = true
		for (var x = 0; x < scheduleModule.totalScheduledRowComponents.length; x++) {
			for (var y = 0; y < scheduleModule.totalScheduledRowComponents[x].rows.length; y++) {
				for (var z = 0; z < scheduleModule.totalScheduledRowComponents[x].rows[y].components.length; z++) {
					if (scheduleModule.totalScheduledRowComponents[x].rows[y].components[z].component_id == component.component_id) {
						scheduleModule.totalScheduledRowComponents[x].rows[y].components[z].selected = true
						scheduleModule.totalScheduledRowComponents[x].rows[y].components[z].changed = true
						return
					}
				}
			}
		}
	}






	compare( a, b ) {
		if ( (new Date(a.start_date)) < (new Date(b.start_date)) ){
			return -1;
		}
		if ( (new Date(a.start_date)) > (new Date(b.start_date)) ){
			return 1;
		}
		return 0;
	}



	_does_component_overlap_in_row(row, component) {
		if (component.hidden) {
			return false
		}
		for (var x = 0; x < row.components.length; x++) {
			var check_component = row.components[x]
			if (check_component.ID == component.ID) {
				continue
			}
			var copied_start = new Date(new Date(component.StartTime + "Z").getTime());
			var copied_end = new Date(new Date(component.StartTime + "Z").getTime());
			copied_end.setDate(copied_end.getDate() + 1)
			var copied_check_start = new Date(new Date(check_component.StartTime + "Z").getTime());
			var copied_check_end = new Date(new Date(check_component.StartTime + "Z").getTime());
			copied_check_end.setDate(copied_check_end.getDate() + 1)
			if (this.sameDay(copied_start, copied_check_start) && !check_component.hidden) {

				return true
			}
		}
		return false
	}




	draggableParentSelectedWrapper;
	dragMouseDown(e, component_id) {
		e = e || window.event;


		e.preventDefault();
		var target_element = e.target
		if (!e.target.hasAttribute('isDraggableComponent')) {
			target_element = e.target.parentElement
		}

	

		if (Object.keys(this.totalSelectedComponents).length == 0) {

			this.draggedElement = target_element
		} else {

			var temp_wrapper_element = document.createElement("div")

			temp_wrapper_element.style.top = target_element.style.top
			temp_wrapper_element.style.left = target_element.style.left
			temp_wrapper_element.style.width = target_element.style.width
			temp_wrapper_element.style.height = target_element.style.height
			temp_wrapper_element.style.position = "absolute"
			temp_wrapper_element.id = "tempSelectionWrapperElementID"
		
		

			var min_x;
			var min_y;
			var width;
			var height;
			target_element.parentElement.appendChild(temp_wrapper_element)
			for (const [key, value] of Object.entries(this.totalSelectedComponents)) {
				// @ts-ignore
				var rect1 =  document.getElementById(key)!.getBoundingClientRect()
				if (!min_x) {
					// @ts-ignore
					min_x = parseInt(value.style.left.replace("px",""))
					min_y = rect1.top 
					// @ts-ignore
					width = rect1.left + (parseInt(value.style.width.replace("px","")) * 2)
					// @ts-ignore
					height = rect1.top + parseInt(value.style.height.replace("px",""))
				} else {
					// @ts-ignore
					if (parseInt(value.style.left.replace("px","")) < min_x) {
						// @ts-ignore
						min_x = parseInt(value.style.left.replace("px",""))
					}
					if (rect1.top < min_y) {
						min_y = rect1.top
					}
					// @ts-ignore
					if (rect1.left + (parseInt(value.style.width.replace("px","")) * 2) > width) {
						// @ts-ignore
						width = rect1.left + (parseInt(value.style.width.replace("px","")) * 2)
					}
					// @ts-ignore
					if ((rect1.top + parseInt(value.style.height.replace("px",""))) > height) {
						// @ts-ignore
						height = rect1.top + parseInt(value.style.height.replace("px",""))
					}
				}
			}


			for (const [key, value] of Object.entries(this.totalSelectedComponents)) {
				
				var element = document.getElementById(key)!
				var rect1 =  element.getBoundingClientRect()
				
				element.style.left = (parseInt(element.style.left.replace("px","")) - min_x).toString() + "px"
				element.style.top = (rect1.top - min_y).toString() + "px" // (parseInt(element.style.top.replace("px","")) - min_y).toString() + "px"
				// @ts-ignore
				temp_wrapper_element.appendChild(element)
			}
			
			var temp_wrapper_element_rect = temp_wrapper_element.getBoundingClientRect()
			temp_wrapper_element.style.left = min_x.toString() + "px"
			temp_wrapper_element.style.width = (width - temp_wrapper_element_rect.left).toString() + "px"
			temp_wrapper_element.style.height = (height - temp_wrapper_element_rect.top).toString() + "px"

			temp_wrapper_element.style.top = (-(target_element.getBoundingClientRect().top - temp_wrapper_element.getBoundingClientRect().top)).toString() + "px"

			this.draggedElement = temp_wrapper_element
		}

		
		
		// get the mouse cursor position at startup:
		target_element.style.zIndex = "99999"

		this.addOriginComponent({
			id: component_id,
			self : this.draggedElement,
			left: this.draggedElement.style.left,
			parent: this.draggedElement.parentElement
		})

		this.pos3 = e.clientX;
		this.pos4 = e.clientY;
		document.onmouseup = this.closeDragElement;
		// call a function whenever the cursor moves:
		document.onmousemove = this.elementDrag;
	}

	elementDrag(e) {

		if (this.isResizingElement) {
			return
		}
		e = e || window.event;
		e.preventDefault();
		// calculate the new cursor position:
		this.pos1 = this.pos3 - e.clientX;
		this.pos2 = this.pos4 - e.clientY;
		this.pos3 = e.clientX;
		this.pos4 = e.clientY;
		// set the element's new position:
		this.draggedElement.style.top = (this.draggedElement.offsetTop - this.pos2) + "px";
		this.draggedElement.style.left = (this.draggedElement.offsetLeft - this.pos1) + "px";
		this.didDragElement = true
	}

	closeDragElement(e) {
		/* stop moving when mouse button is released:*/
		this.isDraggingElement = false
		document.onmouseup = null;
		document.onmousemove = null;
		if (!this.didDragElement) {
			return
		}
		var components = this.getAllChartComponents(e.target)

		for (var x = 0; x < components.length; x++) {
			components[x].style.zIndex = "99999"
		}
		this.didDragElement = false
		var tempSelectionWrapperElementID = document.getElementById("tempSelectionWrapperElementID")
		if (tempSelectionWrapperElementID != null) {
			this.handleDraggedComponentsWrapper()
		} else {
			this.handleDraggedComponent(e)
		}
		this.reshuffleFilterScheduleRowComponents()
		this.isDraggingElement = false


	}


	handleDraggedComponentsWrapper() {
		function pad(n, width, z) {
			z = z || '0';
			n = n + '';
			return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
		}
		

		for (var filter_x = 0; filter_x < this.totalComponents.length; filter_x++) {
		

		// for (var filter_x = 0; filter_x < scheduleModule.totalScheduledRowComponents.length; filter_x++) {
			// for (var y = 0; y < scheduleModule.totalScheduledRowComponents[filter_x].rows.length; y++) {
				// for (var z = 0; z < scheduleModule.totalScheduledRowComponents[filter_x].rows[y].components.length; z++) {
					if (this.totalSelectedComponents["group_row_component_" + this.totalComponents[filter_x].ID] != null) {
						var target_element = document.getElementById("group_row_component_" + this.totalComponents[filter_x].ID)
						var newTargetData = this.handleMultipleDraggedComponents(target_element)
						var newTargetElementDateRange = newTargetData.newTargetElementDateRange
						var draggedElementNewRowID = newTargetData.draggedElementNewRowID
						// @ts-ignore
						var selectedAsset = draggedElementNewRowID.split("<>")[1]
						// for (var check_x = 0; check_x < scheduleModule.totalScheduledRowComponents.length; check_x++) {
						// 	// @ts-ignore
						// 	if (scheduleModule.totalScheduledRowComponents[check_x].title_row.selection_id == draggedElementNewRowID.split("<>")[1]) {
						// 		selectedAsset = scheduleModule.totalScheduledRowComponents[check_x].title_row.selection_name
						// 		break
						// 	}
						// }		


	
						var start_day_add = newTargetElementDateRange[0].getDate()
						var end_day_add = newTargetElementDateRange[0].getDate()
						var offset = (new Date().getTimezoneOffset()) / 60;
						if (offset > parseInt(this.totalComponents[filter_x].StartTime.split("T")[1].split(":")[0])) {
							start_day_add += 1
						}
						if (offset > parseInt(this.totalComponents[filter_x].EndTime.split("T")[1].split(":")[0])) {
							end_day_add += 1
						}

						var start_datetime_check = new Date(newTargetElementDateRange[0].getFullYear() + "-" + pad(newTargetElementDateRange[0].getMonth() + 1, 2, '0') + "-" + pad(start_day_add, 2, '0') + "T" + this.totalComponents[filter_x].StartTime.split("T")[1]);
						var end_datetime_check = new Date(newTargetElementDateRange[1].getFullYear() + "-" + pad(newTargetElementDateRange[1].getMonth() + 1, 2, '0') + "-" + pad(end_day_add, 2, '0') + "T" + this.totalComponents[filter_x].EndTime.split("T")[1]);
						if (end_datetime_check <= start_datetime_check) {
							end_day_add += 1
						}

						var start_datetime_string = newTargetElementDateRange[0].getFullYear() + "-" + pad(newTargetElementDateRange[0].getMonth() + 1, 2, '0') + "-" + pad(start_day_add, 2, '0') + "T" + this.totalComponents[filter_x].StartTime.split("T")[1];
						var end_datetime_string = newTargetElementDateRange[1].getFullYear() + "-" + pad(newTargetElementDateRange[1].getMonth() + 1, 2, '0') + "-" + pad(end_day_add, 2, '0') + "T" + this.totalComponents[filter_x].EndTime.split("T")[1];
					

						scheduleModule.updateComponent({start_datetime: start_datetime_string, end_datetime: end_datetime_string, level: this.reverseAssetsDictionary[selectedAsset].level, asset: selectedAsset, index: filter_x})
						// scheduleModule.setFilterScheduledComponent({
						// 	filter_schedule_component_index: filter_x, 
						// 	row_index: y, 
						// 	component_index: z, 
						// 	start_date: start_datetime_string, 
						// 	end_date: end_datetime_string, 
						// 	well: selectedAsset,
						// })
					}
		}
				// }
			// }
		// }
	}





	// Pulled in from outside
	getComponentDateRangeFromChartLocationWithTemp(component) {

		var target_element_pos = getElementPosition(component, "DRAGGED")
		
		var target_element_min_x = target_element_pos.x
		var target_element_max_x = target_element_pos.x + component.getBoundingClientRect().width

		

		var new_start_of_day = (Math.floor((target_element_min_x - SCHEDULE_PIXELS_TO_CURRENT_DAY) / SCHEDULE_CHART_DAY_WIDTH) + this.chartStartDatetimeWithOffset().getDate())
		var new_end_of_day = (Math.floor((target_element_max_x - SCHEDULE_PIXELS_TO_CURRENT_DAY) / SCHEDULE_CHART_DAY_WIDTH) + this.chartStartDatetimeWithOffset().getDate())
		var firstDayStart = new Date(this.chartStartDatetimeWithOffset().getFullYear(), this.chartStartDatetimeWithOffset().getMonth(), 1);
		var firstDayEnd = new Date(this.chartStartDatetimeWithOffset().getFullYear(), this.chartStartDatetimeWithOffset().getMonth(), 1);
		var new_start_date = firstDayStart.setDate((firstDayStart.getDate()) + (new_start_of_day + 1));
		var new_end_date = firstDayEnd.setDate((firstDayEnd.getDate()) + (new_end_of_day + 1));


		return [new Date(new_start_date), new Date(new_end_date)]
	}




	snapToClosestDayXValueTemp(target_element) {
		var target_element_pos = getElementPosition(target_element, "DRAGGED")
		target_element_pos.x = ((getElementPosition(document.getElementById("tempSelectionWrapperElementID"), "DRAGGED").x + target_element_pos.x) - SCHEDULE_PIXELS_TO_CURRENT_DAY) + SCHEDULE_CHART_DAY_WIDTH
		
		var target_element_min_x = target_element_pos.x
		var closest_day = (Math.floor(((target_element_min_x - (SCHEDULE_CHART_DAY_WIDTH / 1.5)) - SCHEDULE_PIXELS_TO_CURRENT_DAY) / SCHEDULE_CHART_DAY_WIDTH) + this.chartStartDatetimeWithOffset().getDate()) + 0
		target_element.style.left = ((closest_day * SCHEDULE_CHART_DAY_WIDTH) - ((this.chartStartDatetimeWithOffset().getDate() - 1) * SCHEDULE_CHART_DAY_WIDTH)) + "px"
	}

	async snapDraggedElementToRowTemp(target_element, draggedElementNewRowID) {
		var componentRowID = draggedElementNewRowID
		target_element.style.top = "10px"
		document.getElementById(componentRowID)!.appendChild(target_element)
		this.snapToClosestDayXValueTemp(target_element)

	}


	handleMultipleDraggedComponents(target_element) {
		var draggedElementNewRowID = this.getClosestRowIDOnDragEnd(target_element)
		this.snapDraggedElementToRowTemp(target_element, draggedElementNewRowID)
		var newTargetElementDateRange = this.getComponentDateRangeFromChartLocationWithTemp(target_element)
		return {newTargetElementDateRange: newTargetElementDateRange, draggedElementNewRowID: draggedElementNewRowID}
	}



	snapToClosestDayXValue(target_element) {
		var target_element_pos = getElementPosition(target_element, "DRAGGED")
		var target_element_min_x = target_element_pos.x
		var closest_day = (Math.floor(((target_element_min_x - (SCHEDULE_CHART_DAY_WIDTH / 1.5)) - SCHEDULE_PIXELS_TO_CURRENT_DAY) / SCHEDULE_CHART_DAY_WIDTH) + this.chartStartDatetimeWithOffset().getDate()) + 0
		target_element.style.left = ((closest_day * SCHEDULE_CHART_DAY_WIDTH) - ((this.chartStartDatetimeWithOffset().getDate() - 1) * SCHEDULE_CHART_DAY_WIDTH)) + "px"
	}

	snapDraggedElementToRow(target_element, draggedElementNewRowID) {
		var componentRowID = draggedElementNewRowID
		target_element.style.top = "10px"
		document.getElementById(componentRowID)!.appendChild(target_element)
		this.snapToClosestDayXValue(target_element)

	}

	handleDraggedComponent(e) {
		var target_element = e.target
		if (!e.target.hasAttribute('isDraggableComponent')) {
			target_element = e.target.parentElement
		}
		if (!target_element.hasAttribute('isDraggableComponent')) {
			target_element = target_element.parentElement
		}

		var draggedElementNewRowID = this.getClosestRowIDOnDragEnd(target_element)

		this.snapDraggedElementToRow(target_element, draggedElementNewRowID)

		var newTargetElementDateRange = this.getComponentDateRangeFromChartLocation(target_element)

		function pad(n, width, z) {
			z = z || '0';
			n = n + '';
			return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
		}

		// @ts-ignore
		var selectedAsset = draggedElementNewRowID.split("<>")[1]
		// for (var x = 0; x < scheduleModule.totalScheduledRowComponents.length; x++) {
		// 	// @ts-ignore
		// 	if (scheduleModule.totalScheduledRowComponents[x].title_row.selection_id == draggedElementNewRowID.split("<>")[1]) {
		// 		selectedAsset = scheduleModule.totalScheduledRowComponents[x].title_row.selection_name
		// 		break
		// 	}
		// }



		for (var x = 0; x < scheduleModule.totalComponents.length; x++) {
			if (scheduleModule.totalComponents[x].ID == target_element.getAttribute('componentID')) {
				var start_day_add = newTargetElementDateRange[0].getDate()
				var end_day_add = newTargetElementDateRange[0].getDate()
				var offset = (new Date().getTimezoneOffset()) / 60;
				if (offset > parseInt(scheduleModule.totalComponents[x].StartTime.split("T")[1].split(":")[0])) {
					start_day_add += 1
				}
				if (offset > parseInt(scheduleModule.totalComponents[x].EndTime.split("T")[1].split(":")[0])) {
					end_day_add += 1
				}

				var start_datetime_check = new Date(newTargetElementDateRange[0].getFullYear() + "-" + pad(newTargetElementDateRange[0].getMonth() + 1, 2, '0') + "-" + pad(start_day_add, 2, '0') + "T" + scheduleModule.totalComponents[x].StartTime.split("T")[1]);
				var end_datetime_check = new Date(newTargetElementDateRange[1].getFullYear() + "-" + pad(newTargetElementDateRange[1].getMonth() + 1, 2, '0') + "-" + pad(end_day_add, 2, '0') + "T" + scheduleModule.totalComponents[x].EndTime.split("T")[1]);
				if (end_datetime_check <= start_datetime_check) {
					end_day_add += 1
				}

				var start_datetime_string = newTargetElementDateRange[0].getFullYear() + "-" + pad(newTargetElementDateRange[0].getMonth() + 1, 2, '0') + "-" + pad(start_day_add, 2, '0') + "T" + scheduleModule.totalComponents[x].StartTime.split("T")[1];
				var end_datetime_string = newTargetElementDateRange[1].getFullYear() + "-" + pad(newTargetElementDateRange[1].getMonth() + 1, 2, '0') + "-" + pad(end_day_add, 2, '0') + "T" + scheduleModule.totalComponents[x].EndTime.split("T")[1];

				scheduleModule.updateComponent({start_datetime: start_datetime_string, end_datetime: end_datetime_string, level: this.reverseAssetsDictionary[selectedAsset].level, asset: selectedAsset, index: x})
				return 
			}
		}
	}


	@Watch('$route.params.schedule_id', {
		immediate: true,
	})
	async onTasqRouteIdChange(id: string) {
		if (this.existingTableID != id && id != null && id != undefined) {
			this.existingTableID = id
		this.initializePage()
		}
	}

	
	showAddShiftPopup(row){
        this.popupBackground = true
		scheduleModule.setAddShiftRowDetails(row)
		this.showAddShiftPopupVal = true
	}


	async closeEditShiftTemplatePopup() {
		scheduleModule.setActiveShiftTemplateEdit({template: null})
		this.shiftTemplates = await scheduleModule.getShiftTemplates();
		this.isHoveringOverTemplateID = ''
	}

	async closeAddShiftTemplatePopup() {
        this.popupBackground = false
		this.showChangeButtons = true
		this.addShiftTemplatePopupShowing = false
		this.shiftTemplates = await scheduleModule.getShiftTemplates();

	}

	closeAddShiftPopup() {
        this.popupBackground = false
		
		scheduleModule.setAddShiftRowDetails(null)
		this.showAddShiftPopupVal = false
		this.showChangeButtons = true
		this.reshuffleFilterScheduleRowComponents()
	}
	closeEditShiftPopup(component_id, start_date, end_date) {
        this.popupBackground = false
		scheduleModule.setActiveComponent(null)
		this.showChangeButtons = true
		this.reshuffleFilterScheduleRowComponents()
	}

}


